import React from 'react';
import * as styles from './ContactPersonBlock.module.scss';
import classNames from 'classnames';
import {Typography} from "../Typography";
import {PhoneNumberLink} from "../PhoneNumberLink";
import {EmailLink} from "../EmailLink";
import {PageMainColumnWrapper} from "../PageMainColumnWrapper";
import {GatsbyImage, getImage, withArtDirection} from "gatsby-plugin-image";
import {BlockTitle} from "../BlockTitle";
import {componentRef} from "../../functions/componentRef";
import {LinkWithIcon} from "../LinkWithIcon";
import {graphql} from "gatsby";

export const fragment = graphql`
    fragment ContactPersonBlock on ContentfulContactPerson {
        name
        position
        email
        phone
        bigImage {
            localFile {
                childImageSharp {
                    gatsbyImageData(
                        width: 680,
                        height: 1000,
                        placeholder: BLURRED,
                        transformOptions: {cropFocus: NORTH}
                        layout: FIXED
                    )
                }
            }
        }
        mobileBigImage: bigImage {
            localFile {
                childImageSharp {
                    gatsbyImageData(
                        width: 300
                        height: 400
                        placeholder: BLURRED
                        transformOptions: {cropFocus: NORTH}
                        layout: FIXED
                    )
                }
            }
        }
    }
`

export function ContactPersonBlock({person, title, children, mobileImage, image, className, ...otherProps}) {
    const images = withArtDirection(
        getImage(image), [
            {
                media: "(max-width: 68.688rem)",
                image: getImage(mobileImage),
            },
        ]
    );

    return <section
        className={classNames(className, styles.root)} {...otherProps} {...componentRef('contact-person-block')}>
        <PageMainColumnWrapper className={styles.wrapper}>
            <div className={styles.content}>
                <BlockTitle className={styles.title}>{title}</BlockTitle>
                <Typography className={styles.description}>
                    {children}
                </Typography>
                <EmailLink component={LinkWithIcon} withStyles
                           email={person.email}  {...componentRef.subcomponent('email')}/>
                <PhoneNumberLink component={LinkWithIcon}
                                 withStyles
                                 number={person.phone}
                                  {...componentRef.subcomponent('phone')}/>
                <span className={styles.name}>{person.name}</span>
                <span className={styles.position}>{person.position}</span>
            </div>
            <GatsbyImage image={images} className={styles.image} alt={person.name}/>
        </PageMainColumnWrapper>
    </section>;
}


