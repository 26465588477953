// extracted by mini-css-extract-plugin
export var caseStudy = "SectionCaseStudiesBig-module--caseStudy--bq51-";
export var caseStudyIntro = "SectionCaseStudiesBig-module--caseStudyIntro--TpOry";
export var caseStudyLink = "SectionCaseStudiesBig-module--caseStudyLink--7QwYa";
export var caseStudyLinkWrapper = "SectionCaseStudiesBig-module--caseStudyLinkWrapper--ntITC";
export var caseStudyLogo = "SectionCaseStudiesBig-module--caseStudyLogo--CqP44";
export var caseStudyLogoWrapper = "SectionCaseStudiesBig-module--caseStudyLogoWrapper--xuAfW";
export var caseStudyMetrics = "SectionCaseStudiesBig-module--caseStudyMetrics--GeIpQ";
export var caseStudyTitle = "SectionCaseStudiesBig-module--caseStudyTitle--xJb35";
export var description = "SectionCaseStudiesBig-module--description--AHbIQ";
export var header = "SectionCaseStudiesBig-module--header--A0c5P";
export var mainWrapper = "SectionCaseStudiesBig-module--mainWrapper--W3rUO";
export var pagination = "SectionCaseStudiesBig-module--pagination--6BHnL";
export var root = "SectionCaseStudiesBig-module--root--1AWhp";
export var title = "SectionCaseStudiesBig-module--title--1vdYp";
export var wrapper = "SectionCaseStudiesBig-module--wrapper--2MBAM";