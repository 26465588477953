import React from 'react';
import * as styles from './HomeHero.module.scss';
import classNames from 'classnames';
import {Button} from "../Button";
import {Typography} from "../Typography";
import {GatsbyImage} from "gatsby-plugin-image";
import {LangLink} from "../LangLink";
import {useIntl} from 'gatsby-plugin-react-intl';
import {componentRef} from "../../functions/componentRef";

export function HomeHero({title, className, children, image, ...otherProps}) {
    const intl = useIntl();
    return (
        <section className={classNames(className, styles.root)} {...otherProps} {...componentRef('home-hero')}>
            <h1 className={styles.title}
                {...componentRef.subcomponent('home-hero-title')}>
                {title}
            </h1>
            <Typography className={styles.content}>
                {children}
            </Typography>
            <Button component={LangLink} to="/contact"
                    className={styles.cta}
                    {...componentRef.subcomponent('go-digital')}>
                {intl.formatMessage({id: 'button.contactCTA'})}
            </Button>
            <div className={styles.imageWrapper}>
                <GatsbyImage image={image.gatsbyImageData} className={styles.image} alt="Hero"/>
            </div>
        </section>
    );
}
