import React from 'react';
import classNames from 'classnames';
import * as styles from './BlockTitle.module.scss';
import {componentRef} from "../../functions/componentRef";

export function BlockTitle({className, appearance = "normal", noTopMargin = false, children, ...otherProps}) {
    return <h1 data-appearance={appearance}
               className={classNames(className, styles.root, noTopMargin && styles.noTopMargin)} {...otherProps} {...componentRef('block-title')}>
        {children}
    </h1>;
}
