import React from "react"
import {Layout} from "../components/Layout";
import {Testimonials} from "../components/Testimonials";
import {Testimonial} from "../components/Testimonial";
import {PageMainColumnWrapper} from "../components/PageMainColumnWrapper";
import * as styles from './homePage.module.scss';
import {ContactPersonBlock} from "../components/ContactPersonBlock";
import {TestimonialHero} from "../components/TestimonialHero";
import {HomeHero} from "../components/HomeHero";
import {graphql} from "gatsby";
import {MarkdownContent} from "../components/MarkdownContent";
import {rwdImages} from "../functions/rwdImages";
import {BackgroundLine} from "../components/BackgroundLine";
import {injectIntl} from 'gatsby-plugin-react-intl';
import {createSeoData} from "../functions/createSeoData";
import {SectionServices} from "../components/SectionServices";
import {getSubservicesForService} from "../functions/getSubservicesForService";
import {SectionCaseStudiesBig} from "../components/SectionCaseStudiesBig";
import {withArtDirection, getImage} from "gatsby-plugin-image";

export const query = graphql`query HomePage($locale: String!) {
    home: contentfulHomePage(slug: {eq: "home"}, node_locale: {eq: $locale}) {
        heroTitle {
            heroTitle
        }
        heroDescription {
            heroDescription
        }
        heroImage {
            image: localFile {
                childImageSharp {
                    gatsbyImageData(
                        width: 520
                        placeholder: BLURRED
                        transformOptions: {cropFocus: NORTHWEST}
                        layout: CONSTRAINED
                    )
                }
            }
        }
        testimonials {
            rating
            intro {
                intro
            }
            content {
                content
            }
            personName
            personPosition
            personCountry
            logo {
                localFile {
                    url
                    childImageSharp {
                        gatsbyImageData(
                            width: 150
                            height: 80
                            backgroundColor: "transparent"
                            placeholder: BLURRED
                            transformOptions: {fit: CONTAIN}
                            layout: FIXED
                        )
                    }
                }
            }
        }
        servicesTitle
        serviceContent {
            childMarkdownRemark {
                html
            }
        }
        heroReference {
            intro {
                intro
            }
            content {
                content
            }
            personName
            personPosition
            personCountry
            heroImage {
                localFile {
                    childImageSharp {
                        gatsbyImageData(
                            width: 1106
                            height: 622
                            placeholder: BLURRED,
                            transformOptions: {fit: COVER, cropFocus: WEST}
                            layout: FIXED
                        )
                    }
                }
            }
            mobileHeroImage: heroImage {
                localFile {
                    childImageSharp {
                        gatsbyImageData(
                            width: 600
                            height: 260
                            placeholder: BLURRED
                            transformOptions: {cropFocus: EAST}
                            layout: FIXED
                        )
                    }
                }
            }
            logo {
                localFile {
                    url
                    childImageSharp {
                        gatsbyImageData(
                            width: 150
                            height: 80
                            backgroundColor: "transparent"
                            placeholder: BLURRED
                            transformOptions: {fit: CONTAIN}
                            layout: FIXED
                        )
                    }
                }
            }
        }
        contactPersonTitle
        contactPersonContent {
            childMarkdownRemark {
                html
            }
        }
        caseStudyTitle
        caseStudyDescription {
            childMarkdownRemark {
                html
            }
        }
        contactPerson {
            ...ContactPersonBlock
        }
        seo {
            ...Seo
        }
    }
    caseStudies: allContentfulCaseStudy(
        filter: {node_locale: {eq: $locale}}
        limit: 3
        sort: {fields: createdAt, order: DESC}
    ) {
        nodes {
            title
            intro {
                childMarkdownRemark {
                    html
                }
            }
            slug
            color
            metrics {
                name
                value
            }
            logo {
                localFile {
                    childImageSharp {
                        gatsbyImageData(height: 100, placeholder: NONE)
                    }
                }
            }
        }
    }
}
`

function renderHeroReference(heroReference) {

    if (!heroReference) {
        return;
    }

    return (
        <TestimonialHero
            image={{
                fixed: rwdImages(
                    heroReference.heroImage.localFile.childImageSharp.gatsbyImageData,
                    heroReference.mobileHeroImage.localFile.childImageSharp.gatsbyImageData,
                )
            }}
            intro={heroReference.intro.intro}
            description={heroReference.content.content}
            logo={heroReference.logo.localFile}
            person={{
                name: heroReference.personName,
                position: heroReference.personPosition,
                country: heroReference.personCountry
            }}/>
    );
}

function Home({data, intl, pageContext}) {

    const servicesTree = pageContext.services.map(x => {
        return {
            main: x,
            subservices: getSubservicesForService(x.slug, pageContext.oldServices, pageContext.linkableSubServices)
        };
    })
    return (
        <Layout services={pageContext.services}
                oldServices={pageContext.oldServices}
                linkableSubServices={pageContext.linkableSubServices}
                title={intl.formatMessage({id: 'menu.home'})}
                jobsCount={pageContext.jobsCount}
                defaultHrefLang="en"
                seo={createSeoData(data.home.seo)}
                companyData={pageContext.companyData}
                background={<>
                    <BackgroundLine mobileOn={false}/>
                </>}
                lang={pageContext.lang}>
            <PageMainColumnWrapper>
                <HomeHero title={data.home.heroTitle.heroTitle}
                          image={data.home.heroImage.image.childImageSharp}>
                    {data.home.heroDescription.heroDescription}
                </HomeHero>
                <Testimonials className={styles.testimonials}>
                    {data.home.testimonials.map((data, index) => {
                        return <Testimonial
                            key={index}
                            intro={data.intro.intro}
                            description={data.content.content}
                            score={data.rating}
                            logo={data.logo.localFile}
                            person={{
                                name: data.personName,
                                position: data.personPosition,
                                country: data.personCountry
                            }}
                        />;
                    })}
                </Testimonials>
            </PageMainColumnWrapper>

            <SectionServices
                title="Services"
                className={styles.services}
                services={servicesTree}
            />
            <PageMainColumnWrapper>
                {renderHeroReference(data.home.heroReference)}
            </PageMainColumnWrapper>

            <SectionCaseStudiesBig
                title={data.home.caseStudyTitle}
                description={data.home.caseStudyDescription}
                caseStudies={data.caseStudies.nodes}
            />

            <ContactPersonBlock
                title={data.home.contactPersonTitle}
                mobileImage={data.home.contactPerson.mobileBigImage.localFile}
                image={data.home.contactPerson.bigImage.localFile}
                person={{
                    phone: data.home.contactPerson.phone,
                    email: data.home.contactPerson.email,
                    name: data.home.contactPerson.name,
                    position: data.home.contactPerson.position
                }}
            >
                <MarkdownContent content={data.home.contactPersonContent}/>
            </ContactPersonBlock>
        </Layout>
    );
}

export default injectIntl(Home);
