// extracted by mini-css-extract-plugin
export var active = "Testimonial-module--active--E-Z3l";
export var appearance_default = "Testimonial-module--appearance_default--io6cj";
export var appearance_hero = "Testimonial-module--appearance_hero--7KO+L";
export var appearance_secondHero = "Testimonial-module--appearance_secondHero--lINCn";
export var description = "Testimonial-module--description--FTSyK";
export var image = "Testimonial-module--image--yxXRH";
export var intro = "Testimonial-module--intro--qGyfO";
export var logo = "Testimonial-module--logo--41+Q+";
export var name = "Testimonial-module--name--CdsGS";
export var person = "Testimonial-module--person--EQyFy";
export var root = "Testimonial-module--root--M4UJu";
export var star = "Testimonial-module--star--PKi4J";
export var stars = "Testimonial-module--stars--vwARh";