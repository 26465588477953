import React from 'react';
import {Block, sectionBackgroundToAppearance} from "../Block";
import {BlockTitle} from "../BlockTitle";
import classNames from "classnames";
import {PageMainColumnWrapper} from "../PageMainColumnWrapper";
import {LangLink} from "../LangLink";
import {Typography} from "../Typography";
import {MoreLink} from "../MoreLink";

const styles = require('./SectionServices.module.scss');

export function SectionServices({title, className, services, ...otherProps}) {
    return <Block {...otherProps} className={classNames(styles.root, className)}>
        <PageMainColumnWrapper>
            <BlockTitle className={styles.title}>{title}</BlockTitle>

            <Typography className={styles.description}>
                Whether you're an Enterprise or a mid-size company, we give our clients the creative, technical and business talent they need to succeed.
            </Typography>
            {services.map((x, i) => {
                return <div className={styles.serviceWrapper} key={i}>
                    <h2 className={styles.serviceTitle}>{x.main.name}</h2>
                    <div className={styles.serviceMoreLinkContainer}>
                        <MoreLink to={`/service/${x.main.slug}`}>Go to
                            service</MoreLink>
                    </div>
                    <ul className={styles.subServices}>
                        {x.subservices.map((x, i) => {
                            return <li key={i}>
                                <LangLink to={`/service/${x.slug}`} className={styles.subServiceLink}>
                                    {x.name}
                                </LangLink>
                            </li>;
                        })}
                    </ul>
                </div>;
            })}

        </PageMainColumnWrapper>
    </Block>
}
