import {Block} from "../Block";
import {PageMainColumnWrapper} from "../PageMainColumnWrapper";
import React, {useRef} from 'react';
import {Typography} from "../Typography";
import {MarkdownContent} from "../MarkdownContent";
import {SVGSupportImg} from "../SVGSupportImg";
import {Metric} from "../Metric";
import {LangLink} from "../LangLink";
import {BlockTitle} from "../BlockTitle";
import {ArrowPagination} from "../ArrowPagination";
import {useScrollSnap} from "../../hooks/useScrollSnap";
import {useIntl} from "gatsby-plugin-react-intl";

const styles = require('./SectionCaseStudiesBig.module.scss');

export function SectionCaseStudiesBig({title, description, caseStudies}) {
    const scrollRef = useRef(null);

    const [currentIndex, setIndex] = useScrollSnap(scrollRef);
    const max = caseStudies.length;

    return <Block className={styles.root}>
        <PageMainColumnWrapper className={styles.mainWrapper}>
            <div className={styles.header}>
                <BlockTitle className={styles.title}>{title}</BlockTitle>
                <MarkdownContent content={description} component={Typography} className={styles.description}/>
            </div>
            <div className={styles.wrapper} ref={scrollRef}>
                {caseStudies.map((x, i) => {
                    return <CaseStudy key={i}
                                      title={x.title}
                                      intro={x.intro}
                                      introDescription={x.introDescription}
                                      logo={x.logo}
                                      color={x.color}
                                      metrics={x.metrics}
                                      slug={x.slug}
                    />;
                })}
            </div>
            <ArrowPagination className={styles.pagination}
                             hasNext={(currentIndex + 1) < max}
                             hasPrevious={currentIndex > 0}
                             onNextPage={() => setIndex(currentIndex + 1)}
                             onPreviousPage={() => setIndex(currentIndex - 1)}
            />
        </PageMainColumnWrapper>
    </Block>
}

function CaseStudy({title, intro, logo, color, metrics, slug}) {
    const hasMetrics = metrics && metrics.length;
    const intl = useIntl();
    return <article className={styles.caseStudy} data-color={color}>
        <div className={styles.caseStudyLogoWrapper}>
            <SVGSupportImg {...logo.localFile} className={styles.caseStudyLogo} imgStyle={{objectFit: 'contain'}}/>
        </div>
        <h2 className={styles.caseStudyTitle}>{title}</h2>
        <MarkdownContent content={intro} component={Typography} className={styles.caseStudyIntro}/>
        {hasMetrics ?
            <div className={styles.caseStudyMetrics}>
                {(metrics || [])
                    .slice(0, 3)
                    .map((x, i) => {
                        return <Metric
                            size="smaller"
                            key={i}
                            title={x.name}
                            appearance="value-first">
                            {x.value}
                        </Metric>;
                    })}
            </div>
            : undefined
        }
        <div className={styles.caseStudyLinkWrapper}>
            <LangLink to={`/case-study/${slug}`}
                      className={styles.caseStudyLink}>{intl.formatMessage({id: 'caseStudy.read'})}</LangLink>
        </div>
    </article>
}
